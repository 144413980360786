import React from "react";
import { AnimatePresence } from "framer-motion";
import "./src/styles/global.css";

export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode='wait'>
    {element}
  </AnimatePresence>
);

export function shouldUpdateScroll(prevRouterProps, { location }) {
  const body = document.getElementsByTagName("body")[0];
  window.setTimeout(() => body.scrollTo(0, 0), 1400);
  return false;
}
